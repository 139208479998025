import axios from 'axios';
import React from 'react';
import { Formik, FastField, Form } from 'formik';
import Select from 'react-select';
import { format } from 'date-fns';
import { CSVLink } from 'react-csv';

const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
};

const InvoicingReportTable = ({ report, invoiceCadenceOptions, projectTypeOptions, invoiceStatusOptions }) => {

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      const response = await axios.put(`/invoicing_reports/${report.id}`, {
        invoicing_report: {
          ...values,
          invoicing_report_line_items_attributes: Object.values(values.invoicing_report_line_items)
        }
      })
      console.log('Success:', response)
    } catch (error) {
      if (error.response && error.response.data.errors) {
        setErrors({ submit: error.response.data.errors })
        console.error('Error:', error.response.data.errors)
      }
    } finally {
      setSubmitting(false)
    }
  }

  const prepareCSVData = (values) => {
    return Object.values(values.invoicing_report_line_items).map(item => ({
      client_name: item.harvest_client_name,
      project_name: item.harvest_project_name,
      billable_amount: formatCurrency(parseFloat(item.billable_amount)),
      project_type: projectTypeOptions.find(opt => opt[1] === item.project_type)?.[0] || 'N/A',
      invoicing_cadence: invoiceCadenceOptions.find(opt => opt[1] === item.invoicing_cadence)?.[0] || 'N/A',
      last_invoiced_at: item.last_invoiced_at ? format(new Date(item.last_invoiced_at), 'MM/dd/yyyy') : 'N/A',
      retainer_balance: "",
      invoice_status: invoiceStatusOptions.find(opt => opt[1] === item.invoice_status)?.[0] || 'N/A',
      draft_invoice: "",
      drafted_at: item.drafted_at || 'N/A',
      approved_at: item.approved_at || 'N/A',
      notes: item.notes || ''
    }))
  }

  return (
    <Formik
      initialValues={{
        title: report.title || '',
        start_period_at: report.start_period_at || '',
        end_period_at: report.end_period_at || '',
        invoicing_report_line_items: report.invoicing_report_line_items
          .slice()
          .sort((a, b) => b.harvest_client.name.localeCompare(a.harvest_client.name))
          .reduce((acc, item) => {
            acc[item.id] = {
              id: item.id,
              project_id: item.project_id,
              harvest_client_id: item.harvest_client_id,
              harvest_project_id: item.harvest_project_id,
              harvest_client_name: item.harvest_client.name,
              harvest_project_name: item.harvest_project.name,
              uninvoiced_hours: item.uninvoiced_hours,
              billable_amount: item.billable_amount,
              last_invoiced_at: item.last_invoiced_at,
              project_type: item.project_type,
              invoicing_cadence: item.invoicing_cadence,
              invoice_status: item.invoice_status,
              notes: item.notes || "",
              drafted_at: item.drafted_at,
              approved_at: item.approved_at
            };
            return acc;
          }, {})
      }}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur
    >
      {({ values, setFieldValue, submitForm, isSubmitting, errors }) => (
        <Form>
          <div className="d-flex justify-content-between mb-3">
            <h3>{report.title}</h3>
            <CSVLink
              data={prepareCSVData(values)}
              filename={`${report.title.replace(/\s+/g, '_').toLowerCase()}_invoicing_report.csv`}
              className="btn btn-primary"
            >
              Export to CSV
            </CSVLink>
          </div>
          <table className="table table-striped table-bordered">
            <thead className="thead-dark">
              <tr>
                <th>Project Name</th>
                <th>Uninvoiced Hours</th>
                <th>Billable Amount</th>
                <th>Last Invoiced</th>
                <th>Project Type</th>
                <th>Invoicing Cadence</th>
                <th>Invoice Status</th>
                <th>Drafted</th>
                <th>Approved</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              {Object.values(values.invoicing_report_line_items).length === 0 && (
                <tr>
                  <td colSpan="12" className="text-center">No data available</td>
                </tr>
              )}
              {Object.values(values.invoicing_report_line_items).map(item => (
                <tr key={item.id}>
                  <td>
                    <a href={`/projects/${item.project_id}`}>
                      {item.harvest_project_name}
                    </a>
                    <br />
                    <small>
                      <a href={`/harvest_clients/${item.harvest_client_id}`}>
                        {item.harvest_client_name}
                      </a>
                    </small>
                  </td>
                  <td>{item.uninvoiced_hours}</td>
                  <td>{formatCurrency(parseFloat(item.billable_amount))}</td>
                  <td>
                    {item.last_invoiced_at ? format(new Date(item.last_invoiced_at), 'MM/dd/yyyy') : 'N/A'}
                  </td>
                  <td>
                    <Select
                      value={projectTypeOptions.map(option => ({ label: option[0], value: option[1] })).find(opt => opt.value === item.project_type)}
                      onChange={selectedOption => {
                        setFieldValue(`invoicing_report_line_items.${item.id}.project_type`, selectedOption.value);
                        submitForm();
                      }}
                      options={projectTypeOptions.map(option => ({ label: option[0], value: option[1] }))}
                    />
                  </td>
                  <td>
                    <Select
                      value={invoiceCadenceOptions.map(option => ({ label: option[0], value: option[1] })).find(opt => opt.value === item.invoicing_cadence)}
                      onChange={selectedOption => {
                        setFieldValue(`invoicing_report_line_items.${item.id}.invoicing_cadence`, selectedOption.value);
                        submitForm();
                      }}
                      options={invoiceCadenceOptions.map(option => ({ label: option[0], value: option[1] }))}
                    />
                  </td>
                  <td>
                    <Select
                      value={invoiceStatusOptions.map(option => ({ label: option[0], value: option[1] })).find(opt => opt.value === item.invoice_status)}
                      onChange={selectedOption => {
                        setFieldValue(`invoicing_report_line_items.${item.id}.invoice_status`, selectedOption.value);
                        submitForm();
                      }}
                      options={invoiceStatusOptions.map(option => ({ label: option[0], value: option[1] }))}
                    />
                  </td>
                  <td>
                    <FastField
                      type="checkbox"
                      name={`invoicing_report_line_items.${item.id}.drafted_at`}
                      checked={!!item.drafted_at}
                      onChange={(e) => {
                        const newValue = e.target.checked ? new Date().toISOString() : "";
                        setFieldValue(`invoicing_report_line_items.${item.id}.drafted_at`, newValue);
                        submitForm();
                      }}
                    />
                  </td>
                  <td>
                    <FastField
                      type="checkbox"
                      name={`invoicing_report_line_items.${item.id}.approved_at`}
                      checked={!!item.approved_at}
                      onChange={(e) => {
                        const newValue = e.target.checked ? new Date().toISOString() : "";
                        setFieldValue(`invoicing_report_line_items.${item.id}.approved_at`, newValue);
                        submitForm();
                      }}
                    />
                  </td>
                  <td>
                    <FastField
                      type="text"
                      name={`invoicing_report_line_items.${item.id}.notes`}
                      style={{ width: '200px', resize: 'both' }}
                      value={item.notes || ""}
                      onBlur={() => submitForm()}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Form>
      )}
    </Formik>
  )
}

export default InvoicingReportTable;
