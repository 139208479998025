import React, { useMemo, useEffect } from "react"
import { useTable, usePagination, useSortBy } from "react-table"

const ProfitDataTable = ({ data, includeProject, onCsvDataReady }) => {
  const formatCurrency = value => {
    const numericValue = parseFloat(value)
    if (isNaN(numericValue)) return "-"
    return `$${numericValue.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`
  }

  const formatPercentage = value => {
    const numericValue = parseFloat(value)
    return isNaN(numericValue) ? "-" : `${numericValue.toFixed(2)}%`
  }

  const formatTotalsRow = (columns, totals) => {
    return columns.map(column => {
      const totalValue = totals[column.accessor]

      if (!totalValue) return "-"
      if (
        column.accessor.includes("revenue") ||
        column.accessor.includes("cost") ||
        column.accessor.includes("total_comps") ||
        column.accessor.includes("total_writeups") ||
        column.accessor.includes("total_deferred") ||
        column.accessor === "profit" ||
        column.accessor === "profit_without_deferred" ||
        column.accessor === "average_hourly_profit"
      ) {
        return formatCurrency(totalValue)
      } else if (column.accessor === "profit_percentage") {
        return formatPercentage(totalValue)
      } else {
        return totalValue.toString()
      }
    })
  }

  const columns = useMemo(() => {
    const commonColumns = [
      { Header: "Client", accessor: "client" },
      { Header: "Total Hours", accessor: "total_hours" },
      { Header: "Billable Hours", accessor: "billable_hours" },
      {
        Header: "Total Revenue",
        accessor: "total_revenue",
        Cell: ({ value }) => formatCurrency(value),
      },
      {
        Header: "FTE Revenue",
        accessor: "fte_revenue",
        Cell: ({ value }) => formatCurrency(value),
      },
      {
        Header: "Contractor Revenue",
        accessor: "contractor_revenue",
        Cell: ({ value }) => formatCurrency(value),
      },
      {
        Header: "Total Cost",
        accessor: "total_cost",
        Cell: ({ value }) => formatCurrency(value),
      },
      {
        Header: "FTE Cost",
        accessor: "fte_cost",
        Cell: ({ value }) => formatCurrency(value),
      },
      {
        Header: "Contractor Cost",
        accessor: "contractor_cost",
        Cell: ({ value }) => formatCurrency(value),
      },
      {
        Header: "Total Comps",
        accessor: "total_comps",
        Cell: ({ value }) => formatCurrency(value),
      },
      {
        Header: "Total WriteUps",
        accessor: "total_writeups",
        Cell: ({ value }) => formatCurrency(value),
      },
      {
        Header: "Deferred Revenue",
        accessor: "total_deferred",
        Cell: ({ value }) => formatCurrency(value),
      },
      {
        Header: "Profit",
        accessor: "profit",
        Cell: ({ value }) => formatCurrency(value),
      },
      {
        Header: "Profit - Deferred",
        accessor: "profit_without_deferred",
        Cell: ({ value }) => formatCurrency(value),
      },
      {
        Header: "Profit Percentage",
        accessor: "profit_percentage",
        Cell: ({ value }) => formatPercentage(value),
      },
      {
        Header: "Average Hourly Profit",
        accessor: "average_hourly_profit",
        Cell: ({ value }) => formatCurrency(value),
      },
    ]

    if (includeProject) {
      commonColumns.splice(1, 0, {
        Header: "Project",
        accessor: "project_name",
      })
    }

    return commonColumns
  }, [includeProject])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      columns,
      data: data.rows || [],
      initialState: {
        sortBy: [
          { id: "client", desc: false },
          ...(includeProject ? [{ id: "project_name", desc: false }] : []),
        ],
      },
      autoResetPage: false,
      autoResetSortBy: false,
    },
    useSortBy,
  )

  const renderTotalsRow = () => {
    const formattedTotals = formatTotalsRow(columns, data.totals)

    return (
      <tr>
        {formattedTotals.map((formattedValue, index) => (
          <td key={columns[index].accessor}>
            <b>{formattedValue}</b>
          </td>
        ))}
      </tr>
    )
  }

  const csvData = useMemo(() => {
    const headers = columns.map(column => column.Header)
    const dataRows = data.rows.map(row =>
      columns.map(column => {
        const cellValue = row[column.accessor]
        if (
          column.accessor.includes("revenue") ||
          column.accessor.includes("cost") ||
          column.accessor === "profit" ||
          column.accessor === "average_hourly_profit"
        ) {
          return formatCurrency(cellValue)
        } else if (column.accessor === "profit_percentage") {
          return formatPercentage(cellValue)
        }
        return cellValue.toString()
      }),
    )

    const formattedTotals = formatTotalsRow(columns, data.totals)
    return [headers, ...dataRows, formattedTotals]
  }, [data.rows, columns, data.totals])

  useEffect(() => {
    onCsvDataReady(csvData)
  }, [csvData, onCsvDataReady])

  return (
    <>
      <table
        {...getTableProps()}
        className="table table-striped table-bordered"
      >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            )
          })}
          {renderTotalsRow()}
        </tbody>
      </table>
    </>
  )
}

export default ProfitDataTable
